import React from "react";
import "./Bot.css";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Field = ({ fieldTitle, fieldContent }) => {
  const dotsFunction = (value) => {
    const start = value?.length;
    let slicee = value;
    if (start > 15) {
      return slicee.slice(0, 15) + "...";
    } else {
      return slicee;
    }
  };

  return (
    <tr className="Field">
      <th className="FieldTitle">{fieldTitle}</th>
      <th className="FieldContent" title={fieldContent}>
        {dotsFunction(fieldContent)}
      </th>
    </tr>
  );
};

const Bot = ({ botData, operationName }) => {
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme);
  const themeClass = theme === "light" ? "light-theme" : "dark-theme";

  return (
    <div className="BotContainer">
      <div className="BotHeader">
        <h2 className="BotName">{botData.properties.Name}</h2>
        <button
          className="EditBotButton"
          onClick={() =>
            navigate(`edit-bot/${botData.id}?opname=${operationName}`)
          }
        >
          <Icon icon="mdi:pencil-outline" fontSize="20px" /> Edit
        </button>
      </div>
      <div className={`${themeClass} ColContainer`}>
        <div className="BotContainerCol">
          <div className="SectionHeaderContainer">
            <div className={`${themeClass} SectionHeader`}>Details</div>
            <Icon
              icon="material-symbols:info-outline"
              color={theme === "light" ? "#15135b" : "#fff"}
              fontSize="20px"
            />
          </div>
          <Field fieldTitle="Age" fieldContent={botData.properties.Age}></Field>
          <Field
            fieldTitle="Location"
            fieldContent={botData.properties.Location}
          ></Field>
          <Field
            fieldTitle="Phone #"
            fieldContent={botData.formattedBotPhoneNumber}
          ></Field>
          {/* <Field fieldTitle="Ads" fieldContent={botData.ads.join(", ")}></Field> */}
          <Field
            fieldTitle="Description"
            fieldContent={botData.description}
          ></Field>
        </div>
        <div className="BotContainerCol" style={{ marginRight: "0%" }}>
          <div className="SectionHeaderContainer">
            <div className={`${themeClass} SectionHeader`}>Status</div>
            {!botData.isPhoneNumberDeleted ? (
              <Icon
                icon="material-symbols:check-circle"
                color="#7EF182"
                fontSize="20px"
              />
            ) : (
              <Icon icon="ph:x-circle-fill" color="#ff2b2b" fontSize="20px" />
            )}
            <div className="Status">
              {botData.isPhoneNumberDeleted ? "Deactivated" : "Active"}
            </div>
          </div>
          <div className="SectionHeaderContainer">
            <h3 className={`${themeClass} SectionHeader`}>Conversations</h3>
            <Icon
              icon="tabler:messages"
              color={theme === "light" ? "#15135b" : "#fff"}
              fontSize="20px"
            />
          </div>
          <Field
            fieldTitle="Total"
            fieldContent={botData.conversationStatistics.countOfConversations}
          ></Field>
          <Field
            fieldTitle="Total Human-to-Human conversation"
            fieldContent={
              botData.conversationStatistics.countOfHumanConversations
            }
          ></Field>
        </div>
      </div>
    </div>
  );
};

export default Bot;
