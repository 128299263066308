import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import SuspectMessages from "../../components/charts/SuspectMessages";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import NumCard from "../../shared/NumCard/NumCard";
import ListCard from "../../shared/ListCard/ListCard";
import {
  OpsContainer,
  ListHeader,
  ListValue,
  OpsDashboardButton,
  BackLink,
} from "./OperationDashboard.styled";
import { InfoIcon } from "../../shared/styles/InfoIcon.styled";
import { useSelector } from "react-redux";
import { fetchWithAuth } from "../../utils/authPopup";
import { useParams } from "react-router-dom";

const OperationDashboard = ({ instance, orgId }) => {
  const theme = useSelector((state) => state.theme);

  const navigate = useNavigate();
  const [demoChartsInfo, setDemoChartsInfo] = useState();
  const [info, setInfo] = useState();
  const isInitialRender = React.useRef(true);

  const { id } = useParams();

  const handleEdit = () => {
    navigate(`/operations/edit-operation/${id}`);
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      navigate("/operations");
    }
  }, [orgId]);

  async function getOperations(msalContext) {
    const opsList = await fetchWithAuth(
      msalContext,
      "GET",
      `api/organization/${orgId}/operations/${id}/ChartData`
    );

    return opsList;
  }
  async function getOperationsfull(msalContext) {
    const opsList = await fetchWithAuth(
      msalContext,
      "GET",
      `api/organization/${orgId}/operations/${id}`
    );

    return opsList;
  }
  useEffect(() => {
    getOperations(instance).then((response) => {
      setDemoChartsInfo(response);
    });
    getOperationsfull(instance).then((response) => {
      setInfo(response);
    });
  }, []);
  const handleTime = (time) => {
    if (time == null) {
      return "No Data";
    } else {
      return new Date(time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  };

  const handleDate = (time) => {
    if (time == null) {
      return "No Data";
    } else {
      const date = new Date(time);
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = monthNames[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month} ${day}, ${year}`;
    }
  };

  return (
    <OpsContainer>
      <p>
        <Link to="/operations" style={{ textDecoration: "none" }}>
          All Operations
        </Link>{" "}
        &gt; Operation Dashboard
      </p>
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex justify-content-start">
          <h1>{info?.name}</h1>
          <OpsDashboardButton
            className="d-flex"
            color="none"
            onClick={handleEdit}
          >
            <Icon
              className="ms-2 align-self-start"
              icon="mdi:pencil-outline"
              color={`${theme === "light" ? "#15135b" : "#f8f8f8"}`}
            />
          </OpsDashboardButton>
        </div>
        <div className="d-flex align-items-end justify-content-between">
          {/* <PrimaryButton className="me-3">
            <Icon icon="material-symbols:download" color="#fff" /> Export
          </PrimaryButton> */}
          <BackLink to="/operations">
            Back to All <InfoIcon icon="pajamas:go-back" color="#46a6ff" />
          </BackLink>
        </div>
      </div>
      <div>
        <Row className="d-flex justify-content-between">
          <Col sm="4 mt-2">
            <ListCard
              title="Status"
              subtitle={
                <p className="">
                  {info?.isActive === true ? (
                    <>
                      <InfoIcon icon="carbon:in-progress" color="#aaa" />
                      <span>In Progress</span>{" "}
                    </>
                  ) : (
                    <>
                      <InfoIcon
                        icon="fluent-mdl2:completed-solid"
                        color="#7ef182"
                      />
                      <span>Completed</span>
                    </>
                  )}
                </p>
              }
              content={
                <div className="d-flex justify-content-start mb-0 pb-0">
                  <ListHeader>
                    <li>
                      <p>
                        <b>Start Time</b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>End Time</b>
                      </p>
                    </li>{" "}
                    <li>
                      <p>
                        <li>Active Bots</li>
                      </p>
                    </li>{" "}
                    <li>
                      <p>
                        <li>Location </li>
                      </p>
                    </li>
                  </ListHeader>
                  <ListValue>
                    <li>
                      <p>
                        {handleTime(info?.startDate)} / <span> </span>
                        {handleDate(info?.startDate)}
                      </p>
                    </li>
                    <li>
                      <p>
                        {handleTime(info?.endDate)} /<span> </span>
                        {handleDate(info?.endDate)}
                      </p>
                    </li>{" "}
                    <li>
                      <p>
                        <li>{demoChartsInfo?.botsChartDetails?.botsCount}</li>
                      </p>
                    </li>{" "}
                    <li>
                      <p>
                        <li>{info?.location}</li>
                      </p>
                    </li>
                  </ListValue>
                </div>
              }
            />
          </Col>
          <Col sm="4 mt-2">
            <NumCard
              title="Suspects"
              count={demoChartsInfo?.suspectChartDetails?.suspectCount}
              content={
                <ul style={{ listStyle: "none", padding: "0" }}>
                  {demoChartsInfo?.suspectChartDetails?.highRiskSuspectCount >
                  0 ? (
                    <li>
                      <InfoIcon icon="mdi:alert-circle" color="#f24e1e" />
                      {
                        demoChartsInfo?.suspectChartDetails
                          ?.highRiskSuspectCount
                      }{" "}
                      High Risk Suspects
                    </li>
                  ) : (
                    <></>
                  )}
                  {demoChartsInfo?.suspectChartDetails?.mediumRiskSuspectCount >
                  0 ? (
                    <li>
                      <InfoIcon
                        icon="tabler:alert-triangle-filled"
                        color="#ffc700"
                      />
                      {
                        demoChartsInfo?.suspectChartDetails
                          ?.mediumRiskSuspectCount
                      }{" "}
                      Mid Risk Suspects
                    </li>
                  ) : (
                    <></>
                  )}{" "}
                  {demoChartsInfo?.suspectChartDetails?.lowRiskSuspectCount >
                  0 ? (
                    <li>
                      <InfoIcon
                        icon="tabler:alert-triangle-filled"
                        color="#ffc700"
                      />
                      {demoChartsInfo?.suspectChartDetails?.lowRiskSuspectCount}{" "}
                      Low Risk Suspects
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              }
            />
          </Col>
          <Col sm="4 mt-2">
            <NumCard
              title={"Messages"}
              count={demoChartsInfo?.messageChartDetails?.messageCount}
              content={
                <div className="d-flex ">
                  <ul style={{ listStyle: "none", padding: "0" }}>
                    <li>
                      <p>XX</p>
                    </li>
                    <li>
                      <p>XX</p>
                    </li>
                    <li>
                      <p>XX</p>
                    </li>
                  </ul>
                  <ul style={{ listStyle: "none", paddingLeft: "1.5em" }}>
                    <li>
                      <p>Messages Received</p>
                    </li>
                    <li>
                      <p>Bot Responses</p>
                    </li>
                    <li>
                      <p>Human Responses</p>
                    </li>
                  </ul>
                </div>
              }
            />
          </Col>
        </Row>
      </div>
      <div className="mt-5">
        <Row>
          <Col sm="8">
            <SuspectMessages data={[]} />
          </Col>
        </Row>
      </div>
    </OpsContainer>
  );
};

export default OperationDashboard;
