import React, { useState, useEffect, useRef } from "react";
import { Button, Paper, Typography } from "@mui/material";
import { fetchWithAuth } from "../../utils/authPopup.js";
import { Icon } from "@iconify/react";
import { MessageContainer, ChatContainer } from "./ChatPanel.styled";
import { useNavigate } from "react-router-dom";
import { setSelectedSuspect } from "../../redux/slices/suspect";
import { useDispatch, useSelector } from "react-redux";
import classes from "./ChatPanel.module.css";
import { formatDate, messageAuthor } from "./utils/messageFormat.js";
import handleError from "../../utils/handleError.js";
import ImageResizer from "react-image-file-resizer";

const ChatPanel = ({
  conversations,
  selectedChat,
  organizationId,
  instance,
  setMessageWasSent,
  refetchConvo,
}) => {
  const theme = useSelector((state) => state.theme);
  const [editedName, setEditedName] = useState("");

  const [nameEdit, setNameEdit] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const navigate = useNavigate();
  const formattedSuspectData = useSelector(
    (state) => state.suspect.formattedData
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedChat?.id) {
      const conversation = conversations.find(
        (convo) => convo.id === selectedChat.id
      );
      setChatMessages(conversation?.messages);
      setNameEdit(false);
    } else {
      setChatMessages([]);
    }
  }, [conversations, selectedChat]);

  useEffect(() => {
    setShowMessageBox(selectedChat?.humanTakeover);
  }, [conversations, selectedChat]);

  const handleSave = async () => {
    let formattedCopy = { ...selectedChat.suspect };
    formattedCopy.name = editedName;
    await fetchWithAuth(
      instance,
      "PUT",
      `api/organization/${organizationId}/suspects/${selectedChat?.suspect.id}`,
      formattedCopy
    );
    await refetchConvo(selectedChat?.id);
  };

  const handleTakeOver = async () => {
    if (!selectedChat?.id) return alert("pick a conversation first!");

    await fetchWithAuth(
      instance,
      "POST",
      `api/organization/${organizationId}/conversations/${selectedChat?.id}/human-takeover`
    );

    await refetchConvo(selectedChat?.id);

    setShowMessageBox(true);
  };

  const setSuspect = (e) => {
    const suspect = formattedSuspectData.filter(
      (suspect) => suspect.id === selectedChat?.suspect.id
    );
    dispatch(setSelectedSuspect(suspect[0]));
    navigate(`/suspects/suspect-profile/${selectedChat?.suspect.id}`);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    let resize2ndTime = false;

    const resizeImagePreserveAspectRatio = (file, maxWidth, maxHeight) => {
      ImageResizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        "JPEG",
        100,
        0,
        (resizedFile) => {
          if (resizedFile.size < 307200) {
            setSelectedFile(resizedFile);
            setPreviewSrc(URL.createObjectURL(resizedFile));
          } else if (resize2ndTime === false) {
            resize2ndTime = true;
            resizeImagePreserveAspectRatio(file, "auto", 500);
          } else {
            setSelectedFile(file);
            setPreviewSrc(URL.createObjectURL(file));
          }
        },
        "file",
        true
      );
    };

    if (file && file.type.startsWith("image/") && file.size > 307200) {
      resizeImagePreserveAspectRatio(file, 500, "auto");
    } else {
      setSelectedFile(file);
      setPreviewSrc(URL.createObjectURL(file));
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setPreviewSrc(null);
    fileInputRef.current.value = null;
  };

  const handleSendMessage = async () => {
    try {
      const message = { messageText: messageValue, fileUrl: "" };

      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("operationId", selectedChat?.operationId);

        const media = await fetchWithAuth(
          instance,
          "POST",
          `api/organization/${organizationId}/media/upload`,
          formData
        );

        message.fileUrl = media.url;
      }

      handleRemoveFile();

      await fetchWithAuth(
        instance,
        "POST",
        `api/organization/${organizationId}/conversations/${selectedChat?.id}/send-message`,
        message
      );

      await refetchConvo(selectedChat?.id);

      setMessageValue("");
      setMessageWasSent((prev) => !prev);
    } catch (error) {
      handleError(error);
    }
  };

  const handleImageClick = (element) => {
    setClickedImageUrl(element);
    setShowImage(true);
  };

  const handleImageClose = (element) => {
    setClickedImageUrl("");
    setShowImage(false);
  };

  function createImageElementFromUrl(item) {
    if (item.images?.length > 0) {
      return item.images?.map((element, index) => (
        <div key={index}>
          {clickedImageUrl !== element && (
            <>
              <img
                src={element}
                alt="Image"
                style={{
                  width: "100%",
                  height: "auto",
                  filter: "blur(60px)",
                }}
              />
              <button
                style={{
                  backgroundColor: "#2a2f62",
                  color: "white",
                  fontSize: "1.3rem",
                  marginTop: "3px",
                  padding: "8px 16px",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => handleImageClick(element)}
              >
                click to show
              </button>
            </>
          )}
          {clickedImageUrl === element && showImage && (
            <>
              <img
                src={element}
                alt="Image"
                style={{
                  width: "100%",
                  height: "auto",
                  filter: "none",
                }}
              />
              <button
                onClick={() => handleImageClose(element)}
                style={{
                  backgroundColor: "#2a2f62",
                  marginTop: "3px",
                  color: "white",
                  fontSize: "1.3rem",
                  padding: "8px 16px",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                click to close
              </button>
            </>
          )}
        </div>
      ));
    } else {
      return <Typography>{item.text}</Typography>;
    }
  }

  const containerRef = useRef(null);
  useEffect(() => {
    const scrollToBottom = () => {
      if (containerRef.current) {
        const scrollHeight = containerRef.current.scrollHeight;
        const offsetHeight = containerRef.current.offsetHeight;
        containerRef.current.scrollTop = scrollHeight - offsetHeight;
      }
    };
    setTimeout(scrollToBottom, 0);
  }, [selectedChat]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSave();
      setNameEdit(false);
    }
  };
  const handleSentBy = (input) => {
    const letterRegex = /[a-zA-Z]/;

    if (letterRegex.test(input?.suspect?.name)) {
      return input?.suspect?.name;
    }

    return input?.suspectName;
  };

  return (
    <div className={classes.chatPanelContainer}>
      <div className={classes.headerContainer}>
        <Typography className={classes.Typography}>
          Suspect Name:
          <span
            onClick={!nameEdit ? setSuspect : undefined}
            style={{
              marginLeft: "2px",
              color: "#5356FF",
              cursor: "pointer",
              textDecoration: "underline",
              textDecorationThickness: "2px",
              textUnderlineOffset: "4px",
              fontStyle: "italic",
            }}
          >
            {!nameEdit ? (
              selectedChat?.suspect?.name &&
              /[a-zA-Z]/.test(selectedChat?.suspect?.name) ? (
                selectedChat?.suspect?.name
              ) : (
                selectedChat?.suspectPhoneNumber
              )
            ) : (
              <input
                type="text"
                value={editedName}
                onChange={(event) => setEditedName(event.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus
              />
            )}
          </span>
          <span>
            <Icon
              onClick={() => {
                setNameEdit((prev) => !prev);
                setEditedName(
                  selectedChat?.suspect.name &&
                    /[a-zA-Z]/.test(selectedChat?.suspect?.name)
                    ? selectedChat?.suspect?.name
                    : selectedChat?.suspectPhoneNumber
                );
              }}
              className="ms-2 align-self-start"
              icon="mdi:pencil-outline"
              style={{ fontSize: "24px", cursor: "pointer" }}
              color={`${theme === "light" ? "#15135b" : "#f8f8f8"}`}
            />
          </span>
        </Typography>
      </div>
      <ChatContainer
        elevation={3}
        theme={theme}
        isHumanTakeover={selectedChat?.humanTakeover}
        ref={containerRef}
      >
        {chatMessages?.map((message, index) => (
          <MessageContainer key={index} author={message.direction}>
            <Paper
              className={classes.chatBubble}
              style={{
                backgroundColor:
                  message.direction === "IncomingToBot" ? "#46A6FF" : "#F5F5F5",
                color:
                  message.direction === "OutgoingToSuspect"
                    ? "#02041A"
                    : "#000000",
              }}
            >
              {createImageElementFromUrl(message, index)}
            </Paper>
            <Typography className={classes.dateText} variant="caption">
              {`Sent by ${messageAuthor(
                handleSentBy(selectedChat),
                message.direction,
                message.sentByUserName
              )} at ${formatDate(message.sentTime)}`}
            </Typography>
          </MessageContainer>
        ))}
      </ChatContainer>
      <hr style={{ height: "2px", marginTop: "20px" }} />
      <div className={classes.buttonContainer}>
        {!showMessageBox && (
          <>
            <Button
              onClick={handleTakeOver}
              variant="contained"
              color="primary"
              size="large"
              disabled={selectedChat?.isBotPhoneNumberDeleted}
              sx={{
                "&.Mui-disabled": {
                  color: "white",
                },
              }}
            >
              Take Control
            </Button>
          </>
        )}

        {showMessageBox && (
          <div style={{ width: "100%" }}>
            <div className={classes.messageBoxInput}>
              <textarea
                value={messageValue}
                onChange={(e) => setMessageValue(e.target.value)}
                placeholder="Type your message..."
                style={{
                  backgroundColor: theme === "light" ? "#f4f3f8" : "#2a2f62",
                  color: theme === "light" ? "#000000" : "#ffffff",
                }}
              ></textarea>
              <div className={classes.fileUpload}>
                <div className={classes.fileContainer}>
                  <button
                    onClick={handleFileUploadClick}
                    className={classes.iconButton}
                  >
                    <Icon
                      icon="ic:baseline-plus"
                      fontSize={26}
                      color={theme === "light" ? "#000000" : "#ffffff"}
                    />
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              {previewSrc && (
                <img
                  src={previewSrc}
                  alt="preview"
                  className={classes.previewImg}
                  onClick={handleRemoveFile}
                />
              )}
            </div>
            <div className={classes.buttonContainer}>
              <Button
                onClick={handleSendMessage}
                variant="contained"
                color="primary"
                size="large"
              >
                Send
              </Button>
              {selectedChat?.humanTakeover ? (
                <></>
              ) : (
                <Button
                  className={classes.closeButton}
                  onClick={() => setShowMessageBox(false)}
                  variant="outlined"
                  size="large"
                >
                  Close Case
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatPanel;
