import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../utils/authPopup";
import { useSelector } from "react-redux";

const useFetchChatConvos = (instance) => {
  const selectedOrg = useSelector((state) => state.organization.selectedOrg);
  const [conversations, setConversations] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [selectedBot, setSelectedBot] = useState(null);
  const [suspectPhoneNumber, setSuspectPhoneNumber] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [hasMoreConvos, setHasMoreConvos] = useState(true);
  const [continuationToken, setContinuationToken] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const fetchChatConvos = async () => {
    setLoading(true);
    try {
      let url = `api/organization/${selectedOrg.id}/conversations/conversations-paged`;

      let queryParams = [];

      if (selectedOperation) {
        queryParams.push(`operationId=${selectedOperation.id}`);
      }

      if (selectedBot) {
        queryParams.push(`botPhoneNumber=${selectedBot.phoneNumber}`);
      }

      if (suspectPhoneNumber) {
        queryParams.push(`suspectPhoneNumber=${suspectPhoneNumber}`);
      }

      if (continuationToken) {
        queryParams.push(
          `continuationToken=${encodeURIComponent(continuationToken)}`
        );
      }

      if (queryParams.length > 0) {
        url += `?${queryParams.join("&")}`;
      }

      const data = await fetchWithAuth(instance, "GET", url);

      setConversations((prevConversations) => [
        ...prevConversations,
        ...data.conversations,
      ]);

      setContinuationToken(data.continuationToken);

      if (!data.continuationToken) setHasMoreConvos(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const refetchConvo = async (convoId) => {
    try {
      const data = await fetchWithAuth(
        instance,
        "GET",
        `api/organization/${selectedOrg.id}/conversations/${convoId}`
      );

      const updatedConversations = conversations.map((convo) =>
        convo.id === data.id ? data : convo
      );

      setConversations(updatedConversations);
      setSelectedChat(updatedConversations.find((c) => c.id === convoId));
    } catch (error) {
      console.error("Error refetching conversation:", error);
    }
  };

  const addConvo = async (convoId) => {
    try {
      const data = await fetchWithAuth(
        instance,
        "GET",
        `api/organization/${selectedOrg.id}/conversations/${convoId}`
      );

      setConversations((prevConversations) => [
        { ...data, unreadMessages: 1 },
        ...prevConversations,
      ]);
    } catch (error) {
      console.error("Error adding conversation:", error);
    }
  };

  useEffect(() => {
    setSelectedChat(null);
  }, [selectedOrg]);

  useEffect(() => {
    setConversations([]);
    setHasMoreConvos(true);
    setContinuationToken(null);

    // refetch should be handled with another useEffect, so above state changes are reflected.
    setRefetch((prev) => !prev);
  }, [selectedOperation, selectedBot, selectedOrg, suspectPhoneNumber]);

  useEffect(() => {
    if (loading) return;
    fetchChatConvos();
  }, [refetch]);

  return {
    fetchChatConvos,
    refetchConvo,
    addConvo,
    conversations,
    setConversations,
    selectedChat,
    setSelectedChat,
    hasMoreConvos,
    selectedOperation,
    setSelectedOperation,
    selectedBot,
    setSelectedBot,
    suspectPhoneNumber,
    setSuspectPhoneNumber,
  };
};

export default useFetchChatConvos;
